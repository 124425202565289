* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Mulish', sans-serif;
}


body::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
  height: 100%;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
