.btnHovered {
    fill: #2f91ed !important;
    cursor: pointer;
}

.mapdiv{
    width:75%;
    position:relative;
    padding: 2rem;

}

.mapdiv path {
    stroke: #fff;
    stroke-width:4px;
    transition: fill 0.3s;
}

.mapdiv path:hover {
    fill: #2f91ed;
    cursor: pointer;
}

text{
  display:none;
  fill:#fff;
  font-size:1.5em;
  font-weight: 600;

}


g:hover > text{
  display:block;
   fill:#243b85;
   background-color: deepskyblue;

}

